import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BackHomeButton from '../back-home-button/back-home-button';

type FormValues = {
  password: string;
  confirmPassword: string;
  acceptConditions?: boolean;
};

type ChangePasswordFormProps = {
  processingFormData: boolean;
  disabledSubmit?: boolean;
  onSubmitHandler: (password: string, acceptConditions: boolean) => void;
  showAcceptTermsBlock?: boolean;
};

const ChangePasswordForm: FunctionComponent<ChangePasswordFormProps> = (
  props
) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<FormValues>();

  const {
    onSubmitHandler,
    processingFormData,
    disabledSubmit,
    showAcceptTermsBlock,
  } = props;
  const [hiddenPassword, setHiddenPassword] = useState<boolean>(true);
  const [accepted, setAccepted] = useState<boolean>(false);

  const onShowHidePassword = () => {
    setHiddenPassword(!hiddenPassword);
  };

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    onSubmitHandler(formData.password, accepted);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">
            {t('changePasswordForm.newPassword')}
          </Typography>
          {!processingFormData && (
            <Button
              variant="text"
              sx={{ paddingRight: 0 }}
              onClick={onShowHidePassword}
              id="showHidePasswordButton"
            >
              {hiddenPassword
                ? t('changePasswordForm.passwordShow')
                : t('changePasswordForm.passwordHide')}
            </Button>
          )}
        </Stack>
        <TextField
          id="passwordTextField"
          autoFocus
          disabled={processingFormData}
          inputProps={{ tabIndex: 1 }}
          type={hiddenPassword ? 'password' : 'text'}
          placeholder={t('changePasswordForm.placeholder')}
          autoComplete="new-password"
          helperText={errors.password?.message}
          error={!!errors.password?.message}
          fullWidth
          {...register('password', {
            required: t('changePasswordForm.passwordEmptyError') as string,
            minLength: {
              value: 8,
              message: t('changePasswordForm.passwordMinLengthError') as string,
            },
            maxLength: {
              value: 256,
              message: t('changePasswordForm.passwordMaxLengthError') as string,
            },
            validate: {
              // at least one uppercase character are required
              uppercase: (value) =>
                /(\p{Lu})+/u.test(value) ||
                (t(
                  'changePasswordForm.passwordUppercaseRequiredError'
                ) as string),
              // at least one lowercase character are required
              lowercase: (value) =>
                /(\p{Ll})+/u.test(value) ||
                (t(
                  'changePasswordForm.passwordLowercaseRequiredError'
                ) as string),
              // at least one digit character are required
              digits: (value) =>
                /(\d)+/.test(value) ||
                (t('changePasswordForm.passwordDigitsRequiredError') as string),
            },
          })}
        />
      </Box>
      <Box sx={{ paddingTop: '15px' }}>
        <Stack
          id="showHiddenPasswordConfirmStack"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">
            {t('changePasswordForm.confirmPassword')}
          </Typography>
          {!processingFormData && (
            <Button
              variant="text"
              sx={{ paddingRight: 0 }}
              onClick={onShowHidePassword}
              id="showHiddenPasswordConfirmButton"
            >
              {hiddenPassword
                ? t('changePasswordForm.passwordShow')
                : t('changePasswordForm.passwordHide')}
            </Button>
          )}
        </Stack>
        <TextField
          id="passwordConfirmTextField"
          disabled={processingFormData}
          inputProps={{ tabIndex: 2 }}
          type={hiddenPassword ? 'password' : 'text'}
          placeholder={t('changePasswordForm.placeholder')}
          autoComplete="new-password"
          helperText={errors.confirmPassword?.message}
          error={!!errors.confirmPassword?.message}
          fullWidth
          {...register('confirmPassword', {
            required: t('changePasswordForm.passwordEmptyError') as string,
            validate: {
              matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return (
                  password === value ||
                  (t('changePasswordForm.passwordShouldMatch') as string)
                );
              },
            },
          })}
        />
      </Box>
      <FormHelperText>
        {t('changePasswordForm.passwordPolicyHelp')}
      </FormHelperText>
      {showAcceptTermsBlock && (
        <Box sx={{ marginTop: '2vh' }}>
          <Typography variant="body2" gutterBottom>
            {t('acceptTerms.message')}
          </Typography>
          <Typography variant="body1" gutterBottom>
            <Link href="https://planerio.de/AGB/">
              {t('acceptTerms.link1')}
            </Link>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <Link href="https://planerio.de/wp-content/uploads/2022/05/Planerio-Datenschutzerkla%CC%88rung-1.pdf">
              {t('acceptTerms.link2')}
            </Link>
          </Typography>
          <Box>
            <FormControl
              id="acceptTermsForm"
              required
              error={!!errors.acceptConditions?.message}
              component="fieldset"
              variant="standard"
            >
              <FormGroup>
                <FormControlLabel
                  id="acceptTermsLabel"
                  label={t('acceptTerms.checkboxText') as string}
                  style={{ margin: 0 }}
                  control={
                    <Checkbox
                      id="acceptTermsCheckbox"
                      {...register('acceptConditions', {
                        required: 'acceptTerms.error',
                      })}
                      value="accepted"
                      checked={accepted}
                      style={{ marginRight: '8px' }}
                      onChange={() => setAccepted(!accepted)}
                    />
                  }
                />
                {errors.acceptConditions?.message && (
                  <FormHelperText>
                    {t(errors.acceptConditions?.message)}
                  </FormHelperText>
                )}
              </FormGroup>
            </FormControl>
          </Box>
        </Box>
      )}
      <Stack
        id="submitPasswordStack"
        direction="column"
        spacing={2}
        sx={{
          width: '60%',
          margin: '0 auto',
          pt: '3vh',
        }}
      >
        <LoadingButton
          id="submitPasswordLoadingButton"
          type="submit"
          disabled={disabledSubmit && showAcceptTermsBlock && accepted}
          loading={processingFormData}
          variant="contained"
          tabIndex={3}
        >
          {t('changePasswordForm.button')}
        </LoadingButton>
        <BackHomeButton />
      </Stack>
    </form>
  );
};

ChangePasswordForm.defaultProps = {
  disabledSubmit: false,
  showAcceptTermsBlock: false,
};

export default ChangePasswordForm;
