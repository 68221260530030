import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import {
  FunctionComponent,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { RootState } from '../../../../app/store';
import ForgotPasswordButton from '../../../../components/forgot-pasword-button/forgot-password-button';
import { ComponentException } from '../../../../errors/ComponentException';
import {
  clearMessage,
  showMessage,
} from '../../../../features/message/message-slice';
import { setPageTitle } from '../../../../features/page-title/page-title-slice';
import {
  signInCentralAuth,
  signInLegacy,
} from '../../../../features/sign-in/services/direct-auth';
import { useCheckUserByUserNameMutation } from '../../../../features/sign-in/sign-in-api-slice';
import {
  setPassword,
  setSelectedInstance,
  setSuggestedInstances,
  setUsername,
  updateSignInState,
} from '../../../../features/sign-in/sign-in-slice';
import { InstanceEnvironment } from '../../../../features/sign-in/types/InstanceEnvironment';
import { SignInStepType } from '../../../../features/sign-in/types/SignInStepType';
import { getInstanceError } from '../../../../helper/InstanceEnvironmentHelper';
import { logError } from '../../../../helper/LoggerHelper';
import { handleLoginResponse } from '../../../../helper/LoginHelper';
import { getMessagesFromResponseError } from '../../../../helper/request-helper';
import { useOriginInstance } from '../../../../hooks';

type FormValues = {
  username: string;
  password: string;
};

type CheckUsernamePasswordProps = {};

const CheckUsernamePassword: FunctionComponent<
  CheckUsernamePasswordProps
> = () => {
  const { t, i18n } = useTranslation();
  const { username, password } = useAppSelector(
    (state: RootState) => state.signInStore
  );
  const [hiddenPassword, setHiddenPassword] = useState<boolean>(true);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>();
  const [checkUserByUsername, { isLoading }] = useCheckUserByUserNameMutation();
  const [submitIsLoading, setSubmitIsLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const watchUsernameField = watch('username', '');

  useEffect(() => {
    dispatch(setPageTitle({ title: 'login.pageTitle' }));
  }, [watchUsernameField]);

  const originInstance = useOriginInstance();

  const login = async (
    instance: InstanceEnvironment,
    userToLogin?: string,
    passwordToLogin?: string
  ) => {
    const error = getInstanceError(instance);

    if (error) {
      throw new ComponentException(error);
    }

    if (userToLogin && passwordToLogin && instance) {
      console.info(
        'Send request to the selected instance with username and password to login user'
      );

      if (instance.useCentralAuth) {
        const data = await signInCentralAuth(
          userToLogin,
          passwordToLogin,
          instance,
          i18n.language
        );
        handleLoginResponse(data, dispatch);
      } else {
        const data = await signInLegacy(
          userToLogin,
          passwordToLogin,
          instance,
          i18n.language
        );

        if (data.terms) {
          dispatch(updateSignInState(SignInStepType.SignedIn));
        } else {
          dispatch(updateSignInState(SignInStepType.AcceptTerms));
        }
      }
    } else {
      dispatch(
        showMessage({
          type: 'error',
          message: 'login.loginError',
        })
      );
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    try {
      dispatch(clearMessage());
      setSubmitIsLoading(true);
      const suggestedInstances: Array<InstanceEnvironment> =
        await checkUserByUsername({ username: formData.username }).unwrap();

      const availableEnvs = suggestedInstances;

      const origin = availableEnvs.filter(
        (instance) => instance.host === originInstance
      );

      if (availableEnvs.length === 0) {
        throw new Error('login.loginError');
      } else if (availableEnvs.length === 1) {
        dispatch(setUsername(formData.username));
        dispatch(setPassword(formData.password));
        dispatch(setSelectedInstance(availableEnvs[0]));
        await login(availableEnvs[0], formData.username, formData.password);
      } else if (availableEnvs.length > 1 && origin.length === 1) {
        dispatch(setUsername(formData.username));
        dispatch(setPassword(formData.password));
        dispatch(setSelectedInstance(origin[0]));
        await login(origin[0], formData.username, formData.password);
      } else {
        dispatch(
          setSuggestedInstances({
            username: formData.username,
            password: formData.password,
            suggestedInstances: availableEnvs,
          })
        );
      }
    } catch (e) {
      logError(e);
      const errorMessages: string[] = getMessagesFromResponseError(e);
      dispatch(
        showMessage({
          type: 'error',
          messages: errorMessages,
        })
      );
    } finally {
      setSubmitIsLoading(false);
    }
  };

  const onShowHidePassword = () => {
    setHiddenPassword(!hiddenPassword);
  };
  const navigate = useNavigate();
  const onClickLoginWithSSO: MouseEventHandler<HTMLButtonElement> = (
    e
  ): void => {
    e.preventDefault();

    navigate(`/${i18n.language}/sso`, { replace: true });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">{t('login.usernameLabel')}</Typography>
        </Stack>
        <TextField
          id="usernameInputField"
          autoFocus
          inputProps={{ tabIndex: 1 }}
          disabled={isLoading || submitIsLoading}
          placeholder={t('login.usernamePlaceholder')}
          autoComplete="username"
          defaultValue={username}
          helperText={errors.username?.message}
          error={!!errors.username?.message}
          fullWidth
          {...register('username', {
            onChange: (e) => {
              e.target.value = e.target.value.trim();
            },
            required: t('login.usernameEmptyError') as string,
          })}
        />
      </Box>
      <Box sx={{ paddingTop: '15px' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">{t('login.passwordLabel')}</Typography>
          {!(isLoading || submitIsLoading) && (
            <Button
              id="showPasswordBtn"
              variant="text"
              sx={{ padding: 0 }}
              onClick={onShowHidePassword}
            >
              {hiddenPassword
                ? t('login.passwordShowActionButtonLabel')
                : t('login.passwordHideActionButtonLabel')}
            </Button>
          )}
        </Stack>
        <TextField
          id="passwordInputField"
          inputProps={{ tabIndex: 2 }}
          disabled={isLoading || submitIsLoading}
          type={hiddenPassword ? 'password' : 'text'}
          placeholder={t('login.passwordPlaceholder')}
          autoComplete="current-password"
          defaultValue={password}
          helperText={errors.password?.message}
          error={!!errors.password?.message}
          fullWidth
          {...register('password', {
            required: t('login.passwordRequiredErrors') as string,
          })}
        />
      </Box>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          width: '100%',
          margin: '0 auto',
          pt: '3vh',
        }}
      >
        <Box textAlign="center">
          <LoadingButton
            id="doUsernamePasswordLoginBtn"
            type="submit"
            loading={isLoading || submitIsLoading}
            variant="contained"
            tabIndex={3}
          >
            {t('login.submitLabel')}
          </LoadingButton>
        </Box>
        <Box textAlign="center">
          <ForgotPasswordButton disabled={isLoading || submitIsLoading} />
        </Box>
        <Box textAlign="center" sx={{ position: 'relative' }}>
          <Box
            sx={{
              background: '#fff',
              pl: 2,
              pr: 2,
              position: 'relative',
              zIndex: 1,
              display: 'inline-block',
              color: '#A6A7A8',
            }}
          >
            {t('sso.orLoginWithLabel')}
          </Box>
          <Divider
            orientation="horizontal"
            sx={{
              position: 'absolute',
              zIndex: 0,
              width: '100%',
              height: '1px',
              top: '50%',
              left: 0,
              marginBottom: '5vh',
            }}
          />
        </Box>
        <Box textAlign="center">
          <Button
            id="goToSsoBtn"
            variant="outlined"
            type="button"
            onClick={onClickLoginWithSSO}
          >
            SSO
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default CheckUsernamePassword;
