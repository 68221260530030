import { LoadingButton } from '@mui/lab';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import BackHomeButton from '../../../../components/back-home-button/back-home-button';

type FormValues = {
  username: string;
};

type UsernameFormProps = {
  processingFormData: boolean;
  onUsernameDefined: (username: string) => void;
  forgotPassword: boolean;
  disabledSubmit: boolean;
};

const UsernameForm: FunctionComponent<UsernameFormProps> = ({
  processingFormData,
  onUsernameDefined,
  forgotPassword = false,
  disabledSubmit = false,
}: UsernameFormProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const onSubmit: SubmitHandler<FormValues> = async (formData) => {
    onUsernameDefined(formData.username);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="body1">
            {forgotPassword
              ? t('usernameForm.email.label')
              : t('usernameForm.username.label')}
          </Typography>
        </Stack>
        <TextField
          id="forgotPasswordUsernameInputField"
          autoFocus
          disabled={processingFormData || disabledSubmit}
          placeholder={
            forgotPassword
              ? t('usernameForm.email.placeholder')
              : t('usernameForm.username.placeholder')
          }
          autoComplete={forgotPassword ? 'email' : 'username'}
          helperText={errors.username?.message}
          error={!!errors.username?.message}
          inputProps={{
            'data-testid': forgotPassword ? 'forgot-password-email' : '',
          }}
          fullWidth
          {...register(
            'username',
            forgotPassword
              ? {
                  onChange: (e) => {
                    e.target.value = e.target.value.trim();
                  },
                  required: t('usernameForm.email.errors.required') as string,
                  pattern: {
                    // regex from https://emailregex.com/
                    // prettier-ignore
                    // eslint-disable-next-line
                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: t('usernameForm.email.invalid'),
                  },
                }
              : {
                  required: t(
                    'usernameForm.username.errors.required'
                  ) as string,
                }
          )}
        />
      </Box>
      <Stack
        direction="column"
        spacing={2}
        sx={{
          width: '60%',
          margin: '0 auto',
          pt: '3vh',
        }}
      >
        <LoadingButton
          id="submitUsernameBtn"
          type="submit"
          disabled={disabledSubmit}
          loading={processingFormData}
          variant="contained"
        >
          {t('usernameForm.submit.label')}
        </LoadingButton>
        <BackHomeButton />
      </Stack>
    </form>
  );
};

export default UsernameForm;
