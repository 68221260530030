import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import BackHomeButton from '../../../../components/back-home-button/back-home-button';

type Props = {
  label: string;
  submitBtnText: string;
  submitIsLoading: boolean;
  onSubmit: () => void;
  children: React.ReactNode;
};

export const SubmitForm = ({
  label,
  submitBtnText,
  submitIsLoading,
  onSubmit,
  children,
}: Props) => (
  <form onSubmit={onSubmit}>
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body1">{label}</Typography>
      </Stack>
      {children}
    </Box>
    <Stack
      direction="column"
      spacing={2}
      sx={{
        width: '100%',
        margin: '0 auto',
        pt: '3vh',
      }}
    >
      <Box textAlign="center">
        <LoadingButton
          id="softwareTokeSubmitBtn"
          type="submit"
          variant="contained"
          tabIndex={2}
          loading={submitIsLoading}
        >
          {submitBtnText}
        </LoadingButton>
      </Box>
      <Box textAlign="center">
        <BackHomeButton />
      </Box>
    </Stack>
  </form>
);
